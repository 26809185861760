import React, { useState, useRef } from "react";
import "./style.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import FormInput from "../../shared/Form-Input";
import useDebounce from '../../_util/UseDebounce';
import Switch from "react-switch";
import DateRangeModal from '../DateRangeModal/DateRangeModal';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale } from 'primereact/api';
import AdvancedFilterSidebar from "../AdvancedFilterSidebar";
import { Badge } from 'primereact/badge';
import { isMobile, isMobileOnly } from "react-device-detect";
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';


const PageHeader = (props) => {
  let navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [titleDate, setTitleDate] = useState(null)
  const [date, setDate] = useState(null);
  const [rangeInputfield, setRangeInputField] = useState('');
  const [showAdvancedFilter, setShowAdvanedFilter] = useState();
  const [calendarData, setcalendarData] = useState('');
  const calendarRef = useRef(null);
  const initialMount = useRef(true);
  const onPageAction = (type, title = '', id = '', selectedValue = '', toggleVal) => {
    type == "toggle" && !props.toggleVal;
    let data = { actiontype: type, title: title, data: "", id: id, selectedValue: selectedValue, toggleVal: toggleVal };
    props.onPageAction(data);
  };

  const handleFilterData = (data) => {
    setcalendarData(data);
    props.onFilterData(data);
  }

  // addLocale('en', {
  //     firstDayOfWeek: 1,
  //     dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  // });

  locale('en');

  useDebounce(() => { initialMount.current ? initialMount.current = false : onPageAction("search", "", "", searchString); }, [searchString], 1000);
  // Get selected date from date range
  const applyDateRange = () => {
    setRangeInputField(formatDateForDropdown(dateRange[0]) + ' - ' + formatDateForDropdown(dateRange[1]));
    onPageAction('dateRange', '', '', formatDateForDropdown(dateRange[0]) + ' - ' + formatDateForDropdown(dateRange[1]), '');
  }

  // Function to format the date as "MM/DD/YYYY"
  // const formatDateForDropdown = (date) => {
  //   if(date){
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0');
  //     const day = String(date.getDate()).padStart(2, '0');
  //     return `${month}/${day}/${year}`;
  //   }

  const formatDateForDropdown = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}/${day}/${year}`;
    }
  };

  const formatDateForTitle = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = date.toLocaleString('default', { month: 'short' });
      //  const day = String(date.getDate()).padStart(2, '0');
      return `${month} ${year}`;
    }
  };

  const onClick = () => {
    setShowDateRangeModal(true);
  }

  const ClearInput = () => {
    setRangeInputField('');
    setDateRange(null);
    onPageAction('dateRange', '', '', '', '');
  }

  const handleTitleClick = () => {
    if (calendarRef.current) {
      // Trigger the calendar overlay
      calendarRef.current.show(); // Access the internal input ref
    }
  };

  // {props.HeaderItems.find(item => item.type === 'legend') && (
  //   <div className="flex flex-column md:flex-row justify-content-center mx-auto gap-1 md:gap-5">
  //     {props.HeaderItems.find(item => item.type === 'legend').legendItems.map((legend, index) => {
  //       let legendClass = '';
  //       if (legend === 'Private') legendClass = 'private_legend';
  //       else if (legend === 'Scheduled') legendClass = 'scheduled_legend';
  //       else if (legend === 'Fully Booked') legendClass = 'booked_legend';
  //       return (
  //         <div key={index} className="flex gap-1 md:gap-3 legend-info">
  //           <span className="align-self-center">{legend}</span>
  //           <span className={`${legendClass} align-self-center`}></span>
  //         </div>
  //       );
  //     })}
  //   </div>
  // )}


  return (
    <>
      <div className={`viv_pageHeader align-items-center flex justify-content-between ${props?.PageTitle == 'Calendar' ? "pb-0" : "pb-3"} pl-4 pr-4 pt-3`}>
        <div className="flex flex-column align-items-center gap-3">
          {props.breadcrumb && <label className="ml-2">{props.breadcrumb}</label>}
          <div className="page_title flex align-items-center gap-3">
            {props.showBackButton && (
              <i
                className="pi i-arrow-icon cursor-pointer"
                onClick={() => navigate(props.backButtonRoute)}
              ></i>
            )}
            <h3 className={`m-0 page-title ${props?.pageTitleStyleClass ? props?.pageTitleStyleClass : ''}`}>{props?.PageTitle}</h3>
            {props.titleDateRange && (
              <><div className="flex align-items-center gap-2">
                <i className={`i-calender text-xl cursor-pointer`} onClick={handleTitleClick} />
                <label className="cursor-pointer" onClick={handleTitleClick}>{formatDateForTitle(props.date)}</label>
              </div>
                {/* Hidden Calendar input */}
                <Calendar
                  panelClassName='simple-calendar'
                  inpu
                  ref={calendarRef}
                  value={props.date}
                  onChange={(e) => (props.setDate(e.value))}
                  showIcon={false} // Disable the icon if not needed
                  inputStyle={{ display: 'none' }} // Hide the input field
                />
              </>
            )}
            {props.nextPrevHandlers && (
              <><div className="flex next-prev-handler-container">
                <i className={`ic-chev-left cursor-pointer border-circle ${(props.courtLength < 7 || props.courtLength == undefined) && props.calendarType == "court" ? 'p-disabled' : ''}`} onClick={props.handleBack} ></i>
                <i className={`ic-chev-right cursor-pointer border-circle ${(props.courtLength < 7 || props.courtLength == undefined) && props.calendarType == "court" ? 'p-disabled' : ''}`} onClick={props.handleNext} ></i>
              </div>
              </>
            )}
          </div>
        </div>
        {props.HeaderItems.find(item => item.type === 'legend') && !isMobile && (
          <div className="flex md:flex-row justify-content-center mx-auto gap-1 md:gap-5">
            {props.HeaderItems.find(item => item.type === 'legend').legendItems.map((legend, index) => {
              let legendClass = '';
              if (legend === 'Private') legendClass = 'private_legend';
              else if (legend === 'Scheduled') legendClass = 'scheduled_legend';
              else if (legend === 'Fully Booked') legendClass = 'booked_legend';
              return (
                <div key={index} className="flex gap-1 md:gap-3 legend-info">
                  <span className="align-self-center">{legend}</span>
                  <span className={`${legendClass} align-self-center`}></span>
                </div>
              );
            })}
          </div>
        )}

        <div className="page_items align-items-center flex gap-2 actions-container">
          {props.HeaderItems.map((item, i) => {
            if (item.type == "button") {
              return (
                <div
                  key={i}
                  className={item?.containerClass ? item.containerClass : ""}
                >
                  <Button
                    label={item.title}
                    className={item.classes}
                    disabled={item.disabled ? item.disabled : false}
                    onClick={() => onPageAction("button", item.title, item.id)}
                  />
                </div>
              );
            } else if (item.type == "addButton" && !isMobile) {
              return (
                <Button key={i} icon="pi i-plus" label={"Add"} className="btn-primary btn-add btn-header w-10rem" disabled={item?.disabled} onClick={() => onPageAction("addButton")} />
              );
            } else if (item.type == "purchaseButton") {
              return (
                <Button key={i} label={"Purchase Token"} className="btn-purchase" disabled={item?.disabled} onClick={() => onPageAction("purchaseButton")} />
              );
            } else if (item.type == "shareButton") {
              return (
                <>
                  <div id={`shareWalletBtnWrapper`} className="shareWalletWrapper">
                    <Button
                      outlined
                      key={i}
                      label="Share Wallet"
                      className="btn-outline shareWalletButton"
                      disabled={item?.disabled}
                      onClick={() => onPageAction("shareWallet")}
                    />
                  </div>
                  {/* /* PrimeReact Tooltip bound to the button */}
                  {
                    item?.disabled && (
                      <Tooltip
                        target={`#shareWalletBtnWrapper`}
                        content="Only allowed one sharing"
                        position="bottom"
                        className="custom-tooltip-header"
                        showDelay={100}
                        hideDelay={100}
                        tooltipOptions={{ showOnDisabled: true }}
                      />
                    )
                  }
                </>


              );

            } else if (item.type == "toggle") {
              return (
                <div className="headerSwitch flex justify-content-center align-items-center gap-2">
                  <span>{item?.title}</span>
                  <Switch className="flex align-content-center" disabled={props.isSwitchDisabled ? props.isSwitchDisabled : item?.disabled} width={50} height={30} handleDiameter={14} uncheckedIcon={false} checkedIcon={false} offColor={"#d2d5da"} onColor={"#ff6900"} onChange={(e) => onPageAction("toggle", "", "", "", e)} checked={props.toggleVal || false} />
                </div>

                // <Button key={i} icon="pi i-plus" label={"Add"} className="btn-primary btn-header" disabled={item?.disabled} onClick={() => onPageAction("addButton")} />
              );
            } else if (item.type == "historyButton") {
              return (
                <Button key={i} icon="pi i-history" className="btn_headeredit" disabled={item?.disabled} onClick={() => onPageAction("historyButton")} />
              );
            } else if (item.type == "commentButton") {
              return (
                <Button key={i} icon="pi i-comment" className="btn_headeredit" disabled={item?.disabled} onClick={() => onPageAction("commentButton")} />
              );
            } else if (item.type == "deleteButton") {
              return (
                <Button key={i} icon="pi i-bin" className="btn_headeredit" disabled={item?.disabled} onClick={() => onPageAction("deleteButton")} />
              );
            }
            else if (item.type === "text") {
              return (
                <div key={i}>
                  <span className="font-semibold">Last Synced: {props.lastSyncedData}</span>
                </div>
              );
            }
            else if (item.type == "dropdown" && !isMobile) {
              return (
                <Dropdown
                  key={i}
                  value={item.value} // Assuming item.value is the selected value
                  options={item.options || []}
                  onChange={(e) => onPageAction("dropdown", "", "", e.value)}
                  placeholder={item.placeholder || "Select Time Range"}
                  disabled={item?.disabled}
                  optionLabel="name"
                  optionValue="id"
                  style={{ width: "210px" }}
                />
              );
            }
            else if (item.type == "multi-dropdown" && !isMobile) {
              return (
                <MultiSelect key={i} value={item.value}
                  onChange={(e) => onPageAction("multi-dropdown", "", "", e.value)}
                  placeholder={item.placeholder || "Select"}
                  options={item.options || []}
                  optionLabel="name"
                  optionValue="id"
                  display="chip"
                  panelClassName="header-multidd-panel"
                  maxSelectedLabels={3}
                  st
                  style={{ width: "210px" }} />
              );
            }
            else if (item.type == "filter") {
              return (
                <>
                  <i onClick={() => { setShowAdvanedFilter(!showAdvancedFilter) }} className="ic-filter py-1 mr-3 cursor-pointer p-overlay-badge" >
                    {calendarData && calendarData?.length > 0 && <Badge severity="warning" ></Badge>}
                  </i>
                  <AdvancedFilterSidebar visible={showAdvancedFilter} setFilterData={handleFilterData} setVisible={setShowAdvanedFilter} />
                </>
              );
            }

            else if (item.type == "search") {
              return (
                <div className='search flex w-15rem align-items-center justify-content-end pr-2'>
                  <FormInput
                    key={i}
                    placeholder={"Search"}
                    onchange={(e) => setSearchString(e)}
                  />
                  <i className="i-search mr-3" />
                </div>

              );
            }
            else if (item.type == "dateRange") {
              return (
                <><div className='search flex w-17rem align-items-center justify-content-end pl-2 pr-2'>
                  <FormInput
                    key={i}
                    styleclass={"cursor-pointer"}
                    placeholder={"Select Date"}
                    onClick={(e) => onClick(e)}
                    isDisabled={props.dateRangeInactive}
                    value={props.anniversary_date ? props.anniversary_date : rangeInputfield}
                  />
                  {(!props.dateRangeInactive) && (
                    <i className={` ${rangeInputfield ? 'ic-close' : 'i-calender'} mr-3 text-xs cursor-pointer`} onClick={(e) => rangeInputfield ? ClearInput(e) : onClick(e)} />
                  )
                  }
                  {props.dateRangeInactive && (
                    <i className={`i-calender mr-3 text-xs`} />
                  )
                  }
                </div>
                  <DateRangeModal visible={showDateRangeModal} setVisible={setShowDateRangeModal} date={dateRange} setDate={setDateRange} apply={applyDateRange} /></>
              );
            }
            else {
              return <></>;
            }
          })}
        </div>
      </div>

      {isMobile && <div className="grid pl-4 pr-4 mb-2 mt-2">
        {props.HeaderItems.map((item, i) => {
          if (item.type == "button") {
            return (
              <div
                key={i}
                className={`md:col-4 sm:col-12 col-12 ${item?.containerClass ? item.containerClass : ""}`}
              >
                <Button
                  label={item.title}
                  className={item.classes}
                  disabled={item.disabled ? item.disabled : false}
                  onClick={() => onPageAction("button", item.title, item.id)}
                />
              </div>
            );
          } else if (item.type == "addButton") {
            return (
              <div className="md:col-6 sm:col-12 col-12">
                <Button key={i} icon="pi i-plus" label={"Add"} className="btn-primary w-full btn-add btn-header" disabled={item?.disabled} onClick={() => onPageAction("addButton")} />
              </div>
            );
          }
          else if (item.type == "dropdown") {
            return (
              <div className="md:col-6 sm:col-12 col-12">
                <Dropdown
                  key={i}
                  value={item.value} // Assuming item.value is the selected value
                  options={item.options || []}
                  onChange={(e) => onPageAction("dropdown", "", "", e.value)}
                  placeholder={item.placeholder || "Select Time Range"}
                  disabled={item?.disabled}
                  optionLabel="name"
                  optionValue="id"
                  style={{ width: "100%" }}
                />
              </div>
            );
          }
          else if (item.type == "multi-dropdown" && !isMobile) {
            return (
              <div className="md:col-6 sm:col-12 col-12">
                <MultiSelect key={i} value={item.value}
                  onChange={(e) => onPageAction("multi-dropdown", "", "", e.value)}
                  placeholder={item.placeholder || "Select"}
                  options={item.options || []}
                  optionLabel="name"
                  optionValue="id"
                  display="chip"
                  panelClassName="header-multidd-panel"
                  maxSelectedLabels={3}
                  st
                  style={{ width: "100%" }} />
              </div>

            );
          }
        })}

      </div>}

      {props.HeaderItems.find(item => item.type === 'legend') && isMobile &&
        (
          <div className="flex md:flex-row mt-3 justify-content-center mx-auto gap-1 md:gap-5">
            {props.HeaderItems.find(item => item.type === 'legend').legendItems.map((legend, index) => {
              let legendClass = '';
              if (legend === 'Private') legendClass = 'private_legend';
              else if (legend === 'Scheduled') legendClass = 'scheduled_legend';
              else if (legend === 'Fully Booked') legendClass = 'booked_legend';
              return (
                <div key={index} className="flex gap-1 md:gap-3 legend-info">
                  <span className="align-self-center">{legend}</span>
                  <span className={`${legendClass} align-self-center`}></span>
                </div>
              );
            })}
          </div>
        )}
    </>
  );
};

export default PageHeader;
