import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import exclamationMarkIcon from '../../assets/images/exclamation-mark.svg';
import "./style.scss";
const ConfirmationPopup = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    showPopUp() {
      setVisible(true);
    },
    hidePopUp() {
      setVisible(false);
    }
  }));

  const footerContent = (
    <div className={`mt-3 flex ${props.Resend ? 'justify-content-center' : 'justify-content-end'} align-items-center gap-3`}>
      <Button
        label={props.cancelBtnLabel ? props.cancelBtnLabel : "No"}
        onClick={() => props.onCancel()}
        className="btn-secondary w-8rem"
      />
      <Button label={props.confirmBtnLabel ? props.confirmBtnLabel : "Yes"} onClick={() => props.onConfirm()} 
       className={`${props.Resend ? 'w-16rem' : 'w-8rem'} btn-primary`}
       />
    </div>
  );

  return (
    <Dialog
      header={props.title}
      visible={visible}
      draggable={false}
      resizable={false}
      className="export_popup"
      style={{ width: "33vw" }}
      onHide={() => props.onHide()}
      footer={footerContent}
      closable={props?.closable ? false : true}
    >
      <div className="flex justify-content-center align-items-center flex-column gap-3">
        {/* <img src={exclamationMarkIcon} alt="" /> */}
        <i className="ic-exclamation-circle popup-icon"></i>
        <p>{props.message}</p>
        </div>
    </Dialog>
  );
});

export default ConfirmationPopup;