import React, { useEffect, useRef, useState } from "react";
import httpClient from "../../_util/api";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import AddEvent from "../../pages/Franchise/calendar/addEvent";
import EventListDialog from "../../pages/Franchise/calendar/events-list-dialog";
import EventViewDialog from "../../pages/Franchise/calendar/event-view-dialog";
import MonthEventViewDialog from "../../pages/Franchise/calendar/month-event-dialog";
import { Tooltip } from 'primereact/tooltip';
import { hideLoader, setNotificationCount, setEventSuccess, showLoader } from "../../redux/mainSlice";
import VivToast from "../../shared/VivitechToast";
import SlotReserveDialog from "../../pages/Franchise/calendar/slot-reserve-dialog";
import PrivateSlotReserveDialog from "../../pages/Franchise/calendar/private-slot-reserve-dialog";
import WaitListConfirmation from "../../shared/SuccessPopup";
import SlotListDialog from "../../pages/Franchise/calendar/slot-list-dialog";
import DayViewDialog from "../../pages/Franchise/calendar/day-views-dialog";
import { isMobileOnly as isMobile } from "react-device-detect";
import ReservationCancelDialog from "../../pages/Franchise/calendar/reservation-cancel-dialog";
import TokenListDialog from "../../pages/Franchise/calendar/token-list-dialog";
import { v4 as uuidv4 } from "uuid";

const MonthCalander = ({ calendarDate, filteredEventData, path, apiEndpoint }) => {

    const [currentDate, setCurrentDate] = useState(`${new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date()?.getFullYear()}`);
    const [weekDates, setWeekDates] = useState([]);
    const [monthDates, setMonthDates] = useState([]);
    const [addEvent, setAddEvent] = useState(false)
    const [eventListShow, setEventListShow] = useState(false)
    const eventChange = useSelector((state) => state.mainSlice.eventSuccess);
    const locationId = useSelector((state) => state.mainSlice.locationId);
    const [eventPopupData, setEventPopupData] = useState([])
    const dispatch = useDispatch();
    const toast_Ref = useRef(null);
    const WaitListConfirmationRef = useRef(null);
    const [slotReserveShow, setSlotReserveShow] = useState(false)
    const [slotListShow, setSlotListShow] = useState(false)
    const [tokenListShow, setTokenListShow] = useState(false)
    const [dayViewShow, setDayViewShow] = useState(false)
    const [privateSlotReserveShow, setPrivateSlotReserveShow] = useState(false)
    const [cancelSlotReserveShow, setCancelSlotReserveShow] = useState(false)
    const [eventViewShow, setEventViewShow] = useState(false)
    const [moreEventPopupData, setMoreEventPopupData] = useState([])
    const [moreEventViewShow, setMoreEventViewShow] = useState(false)
    const [createDate, setCreateDate] = useState()
    const [events, setEvents] = useState([]);


    const getEventSlots = async (from_date, to_date) => {
        dispatch(showLoader())
        try {
            const resp = await httpClient.post(`${apiEndpoint ? apiEndpoint : path == "my-reservation" ? '/my-reservations' : '/event-slots'}`, {
                event_type_id: filteredEventData,
                location_id: locationId,
                frequency: "month",
                from_date: from_date,
                to_date: to_date
            });
            setEvents(resp.data.data);
            getNotificationsCount()
        } catch (error) {
            setEvents([]);
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }


    const getNotificationsCount = async () => {
        let apiResp = await httpClient.get("whomi")

        if (apiResp?.status == 200) {
            dispatch(setNotificationCount(apiResp.data.data.user.unread ? apiResp.data.data.user.unread : 0))
        }
    }

    useEffect(() => {
        setCalendarView(calendarDate)


    }, [eventChange, calendarDate, filteredEventData, locationId]);


    const setCalendarView = async (date) => {

        let curr = date;
        let first = curr.getDate() - curr.getDay();
        let last = first + 6;
        let firstDate = new Date(curr.getFullYear(), curr.getMonth(), first);
        let lastDate = new Date(curr.getFullYear(), curr.getMonth(), last);

        let firstday = firstDate.getDate();
        let lastday = lastDate.getDate();
        let dates_arr = [];

        dates_arr.push(firstday);
        for (let date = firstday; date < lastday; date++) {
            dates_arr.push(date + 1);
        }

        setWeekDates(dates_arr);
        getWeeksOfCurrentMonth(curr.getMonth() + 1, curr.getFullYear());
    }

    const getWeeksOfCurrentMonth = (month, year) => {
        const weeksArray = [];
        const monthIndex = month - 1; // Convert month to 0-based index
        const monthName = new Date(year, monthIndex).toLocaleString('en-US', { month: 'long' }); // Get the full month name
        const daysInMonth = new Date(year, monthIndex + 1, 0).getDate(); // Get number of days in the specified month
        let currentWeek = [];

        // Get the first day of the specified month
        const firstDayOfMonth = new Date(year, monthIndex, 1);
        const lastDayOfMonth = new Date(year, monthIndex + 1, 0).toLocaleString('en-US', { weekday: 'short' });
        const firstDayIndex = firstDayOfMonth.getDay(); // 0 for Sun, 1 for Mon, etc.

        // If the first day of the month is not Sunday, fill the start of the week with the previous month's dates
        if (firstDayIndex !== 0) {
            const prevMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1;
            const prevYear = monthIndex === 0 ? year - 1 : year;
            const daysInPrevMonth = new Date(prevYear, prevMonthIndex + 1, 0).getDate();
            const prevMonthName = new Date(prevYear, prevMonthIndex).toLocaleString('en-US', { month: 'long' });

            // Add dates from the previous month to fill the start of the week
            for (let i = firstDayIndex - 1; i >= 0; i--) {
                const prevMonthDay = daysInPrevMonth - i;
                const formattedDate = prevMonthDay < 10 ? `0${prevMonthDay}` : prevMonthDay;

                currentWeek.unshift({
                    date: prevMonthDay,
                    day: new Date(prevYear, prevMonthIndex, prevMonthDay).toLocaleString('en-US', { weekday: 'short' }),
                    month: prevMonthName,
                    complete_date: `${formattedDate}-${((prevMonthIndex + 1) >= 10) ? (prevMonthIndex + 1) : `0${prevMonthIndex + 1}`}-${prevYear}` // Adjusted to use the correct values
                });
            }
        }

        // Add the current month's dates
        for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(year, monthIndex, i);
            const day = date.toLocaleString('en-US', { weekday: 'short' }); // Get the day of the week

            currentWeek.push({ date: i, day: day, month: monthName, complete_date: `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${date?.getFullYear()}` });

            const dayIndex = date.getDay(); // Get the index of the day (0 for Sun, 1 for Mon, etc.)

            // If the day is Saturday (end of the week) or it's the last day of the month
            if (dayIndex === 6 || i === daysInMonth) {
                weeksArray.push({ week: currentWeek });
                currentWeek = []; // Start a new week
            }
        }


        // If the last day of the month is not Saturday, fill the rest of the week with the next month's dates
        if (lastDayOfMonth !== "Sat") {
            const nextMonthIndex = monthIndex === 11 ? 0 : monthIndex + 1;
            const nextYear = monthIndex === 11 ? year + 1 : year;
            const nextMonthName = new Date(nextYear, nextMonthIndex).toLocaleString('en-US', { month: 'long' });

            let nextDayIndex = currentWeek.length; // Current length of the week array determines the next day
            for (let i = 1; nextDayIndex < 7; i++, nextDayIndex++) {
                currentWeek.push({
                    date: i,
                    day: new Date(nextYear, nextMonthIndex, i).toLocaleString('en-US', { weekday: 'short' }),
                    month: nextMonthName,
                    complete_date: `${i < 10 ? '0' + i : i}-${(nextMonthIndex + 1) < 10 ? '0' + (nextMonthIndex + 1) : nextMonthIndex + 1}-${nextYear}`
                });
            }

            const dates_count_to_add = lastDayOfMonth == "Sun" ? 6 : lastDayOfMonth == "Mon" ? 5 : lastDayOfMonth == "Tue" ? 4 : lastDayOfMonth == "Wed" ? 3 : lastDayOfMonth == "Thu" ? 2 : lastDayOfMonth == "Fri" ? 1 : 0
            if (currentWeek.length) {
                for (let index = 0; index < dates_count_to_add; index++) {
                    weeksArray[weeksArray.length - 1].week.push({
                        date: currentWeek[index].date,
                        day: currentWeek[index].day,
                        month: currentWeek[index].month,
                        complete_date: currentWeek[index].complete_date
                    });
                }
            }
        }


        // Ensure "Sun" is the first day in first row
        weeksArray.forEach((weekObj, ind) => {

            if (ind == 0) {
                const week = weekObj.week;
                const sundayIndex = week.findIndex(dayObj => dayObj.day === "Sun");
                if (sundayIndex > 0) {
                    const sunday = week.splice(sundayIndex, 1)[0];
                    week.unshift(sunday);
                }
            }
        });

        setMonthDates(sortWeeks(weeksArray));


        getEventSlots(weeksArray[0].week[0].complete_date, weeksArray[weeksArray.length - 1].week[weeksArray[weeksArray.length - 1].week.length - 1].complete_date)

        return weeksArray;
    }


    const sortWeeks = (weeksArray) => {
        const dayOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        const sortWeek = (weekArray) => {
            return weekArray.sort((a, b) => {
                return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
            });
        };

        return weeksArray.map(weekObj => {
            return {
                ...weekObj,
                week: sortWeek(weekObj.week)
            };
        });
    };

    const handleShowMoreEvents = (data, event) => {
        event.stopPropagation()
        setEventPopupData(data)

        if (path == "location-schedule" || path == "my-reservation") {
            setSlotListShow(true)
        } else {
            setEventListShow(true)
        }

    }

    const handleShowMoreEventsPopup = (data, event) => {
        event.stopPropagation();
        setMoreEventPopupData(data);
        setMoreEventViewShow(true);
    }

    const handleEventShow = (data, event) => {
        event.stopPropagation()
        //console.log(data);

        setEventPopupData(data)
        if (path == 'location-schedule' || path == "my-reservation") {

            if (data && data.event_type_separate == 'scheduled_play') {
                if (data?.fully_booked) {
                    WaitListConfirmationRef.current.showPopUp()
                } else {
                    if (path == 'location-schedule') {
                        setSlotReserveShow(true)
                    } else {
                        setCancelSlotReserveShow(true)
                    }
                }
            } else {
                setPrivateSlotReserveShow(true)
            }
        } else {
            setEventViewShow(true)
        }

        // setDayViewShow(true)
    }

    const handleDayViewMobile = (data, event) => {
        event.stopPropagation()
        //console.log("mobile");

        setEventPopupData(data)
        setDayViewShow(true)
    }

    const onConfirmWaitList = async () => {
        dispatch(showLoader())

        try {
            const resp = await httpClient.post("/wait-list", {
                event_id: eventPopupData.event_id,
                occurrence_date: eventPopupData.occurrence_date,
                start_time: eventPopupData.start_time,
                end_time: eventPopupData.end_time
            });

            if (resp.status == 200) {
                toast_Ref.current?.showMessage(
                    "success",
                    "Waitlist Joined",
                    "",
                    "ic-square-check"
                )
                dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
                setTimeout(() => {
                    WaitListConfirmationRef.current.hidePopUp()
                }, 500)
            }

        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
        }
        dispatch(hideLoader())
    }

    function formatTimeRange(startTime, endTime) {
        // const formatTime = (time) => {
        //     // Convert time string (e.g., "02:00 AM") to a Date object
        //     const [hour, minutePart] = time.split(':');
        //     const [minute, period] = minutePart.split(' ');

        //     // Convert hour to a number and remove leading zeros
        //     let hourNum = parseInt(hour, 10);

        //     // Format hour without leading zero for 12-hour format
        //     return `${hourNum} ${period}`;
        // };

        // // Format both start time and end time
        // const formattedStartTime = formatTime(startTime);
        // const formattedEndTime = formatTime(endTime);

        // return `${formattedStartTime} - ${formattedEndTime}`;
        return `${startTime} - ${endTime}`;
    }


    const handleEventRender = (day, rowInd) => {
        const event = events.find((x) => x.date == day.complete_date);

        if (event) {
            const showMoreEventLength = event.events.filter((e, ind, arr) => arr.findIndex(ev => ev.start_time === e.start_time && ev.end_time === e.end_time) === ind).length;
            return (
                <div className="col calendar-row-cell overflow-hidden" onClick={(e) => handleEventCreateFromCalendar(day.complete_date)}>
                    <div className="calendar-row-cell-header w-ful text-center">
                        {rowInd == 0 && <p className={`${day?.complete_date == currentDate ? 'weekday-active' : ''} weekday`}>{day?.day}</p>}
                        <p className={`${day?.complete_date == currentDate ? 'weekdate-active' : ''} weekdate`}>{day?.date}</p>
                    </div>

                    <div className="calendar-row-cell-body mt-2 px-1" /* className={`calendar-row-cell-body ${rowInd == 0 ? 'mt-2' : 'mt-5'}`} */>

                        {event.events
                            .filter((e, ind, arr) =>
                                arr.findIndex(ev => ev.start_time === e.start_time && ev.end_time === e.end_time) === ind
                            )
                            .map((e, ind) => {
                                const sameTimeEvents = event.events.filter(event => event.start_time === e.start_time && event.end_time === e.end_time);
                                if (!isMobile) {
                                    return (
                                        <div key={e.event_id} className="w-full flex justify-content-between month-event-description" onClick={(e) => e.stopPropagation()}>
                                            <Tooltip target={`.event-name-${e.event_id}-${e.occurrence_date}`} position="top" content={e.event_type_name} showDelay={100} hideDelay={100} />
                                            <p className={`event-name-${e.event_id}-${e.occurrence_date} ${sameTimeEvents.length <= 1 && `cursor-pointer`} `} onClick={(ev) => { sameTimeEvents.length <= 1 && handleEventShow(e, ev) }} > {formatTimeRange(e.start_time, e.end_time)} <b>{e.event_type_name}</b>
                                            </p>
                                            {sameTimeEvents.length > 1 && (
                                                <span onClick={(ev) => handleShowMoreEvents(sameTimeEvents, ev)} className="cursor-pointer">
                                                    <b>{sameTimeEvents.length - 1}+</b>
                                                </span>
                                            )}
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div className="event-card-mob primary-bg" onClick={(ev) => { handleDayViewMobile(e, ev) }}>
                                            <p className="title">{isMobile ? e.event_type_name.substring(0, 6) + '..' : e.event_type_name}</p>
                                        </div>
                                    )
                                }

                            })
                            .splice(0, isMobile ? 4 : 3)
                        }

                        {showMoreEventLength > 3 && !isMobile && (
                            <div onClick={(ev) => handleShowMoreEventsPopup(event?.events, ev)} className="more-event-div cursor-pointer flex justify-content-center align-content-center mt-2 p-1">
                                <span>{showMoreEventLength - 3} More</span>
                            </div>
                        )}

                        {
                            showMoreEventLength > 3 && isMobile && (
                                <div>
                                    <p onClick={(ev) => { handleDayViewMobile(event.events[0], ev) }}>...</p>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="col calendar-row-cell" onClick={(e) => path != "location-schedule" && handleEventCreateFromCalendar(day.complete_date)
                }>
                    <div className="calendar-row-cell-header w-ful text-center">
                        {rowInd == 0 && <p className={`${day?.complete_date == currentDate ? 'weekday-active' : ''} weekday`}>{day?.day}</p>}
                        <p className={`${day?.complete_date == currentDate ? 'weekdate-active' : ''} weekdate`}>{day?.date}</p>
                    </div>
                </div>
            )
        }

    }

    const handleTokenStaticsEventRender = (day, rowInd) => {
        const event = events.find((x) => x.date == day.complete_date);
        
        if (event) {
            //const showMoreEventLength = event.events.filter((e, ind, arr) => arr.findIndex(ev => ev.start_time === e.start_time && ev.end_time === e.end_time) === ind).length;
            return (
                <div className="col calendar-row-cell overflow-hidden">
                    <div className="calendar-row-cell-header w-ful text-center">
                        {rowInd == 0 && <p className={`${day?.complete_date == currentDate ? 'weekday-active' : ''} weekday`}>{day?.day}</p>}
                        <p className={`${day?.complete_date == currentDate ? 'weekdate-active' : ''} weekdate`}>{day?.date}</p>
                    </div>

                    <div className="calendar-row-cell-body mt-2 px-1" /* className={`calendar-row-cell-body ${rowInd == 0 ? 'mt-2' : 'mt-5'}`} */>

                        {event.events.map((e, ind) => {
                            let unique_id = uuidv4();
                                if (!isMobile) {
                                    return (
                                        <div key={ind} className="w-full flex justify-content-between month-event-description" onClick={(e) => e.stopPropagation()}>
                                            <Tooltip target={`.event-name-${unique_id}`} position="top" content={e.event_type} showDelay={100} hideDelay={100} />
                                            <p className={`event-name-${unique_id}`}> {e.total_tokens} <b>{e.event_type}</b></p>
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div className="event-card-mob primary-bg">
                                            <p className="title">{isMobile ? e.event_type.substring(0, 6) + '..' : e.event_type}</p>
                                        </div>
                                    )
                                }

                            })
                            .splice(0, isMobile ? 4 : 3)
                        }

                        {event.events?.length > 3 && !isMobile && (
                            <div onClick={(ev) => handleShowMoreTokensPopup(event?.events, ev)} className="more-event-div cursor-pointer flex justify-content-center align-content-center mt-2 p-1">
                                <span>{event.events?.length - 3} More</span>
                            </div>
                        )}

                        {
                            event.events?.length > 3 > 3 && isMobile && (
                                <div>
                                    <p onClick={(ev) => { handleDayViewMobile(event.events[0], ev) }}>...</p>
                                </div>
                            )
                        }
                        
                    </div>
                    <h6 className="tokens-sum">Total {event?.grand_total_tokens}</h6>
                </div>
            )
        }
        else {
            return (
                <div className="col calendar-row-cell">
                    <div className="calendar-row-cell-header w-ful text-center">
                        {rowInd == 0 && <p className={`${day?.complete_date == currentDate ? 'weekday-active' : ''} weekday`}>{day?.day}</p>}
                        <p className={`${day?.complete_date == currentDate ? 'weekdate-active' : ''} weekdate`}>{day?.date}</p>
                    </div>
                </div>
            )
        }

    }

    const handleShowMoreTokensPopup = (data, event) => {
        event.stopPropagation();
        setEventPopupData(data);
        setTokenListShow(true);
    }


    const handleEventCreateFromCalendar = (date) => {
        if(path !== "location-schedule" && path !== "my-reservation"){
            setCreateDate(date)
            setAddEvent(true)
        }
    }

    return (<>
        <EventListDialog visible={eventListShow} onChange={() => setEventListShow(false)} data={eventPopupData} />
        <DayViewDialog visible={dayViewShow} onChange={() => setDayViewShow(false)} data={eventPopupData} filteredData={filteredEventData} path={path} />
        <EventViewDialog visible={eventViewShow} onChange={() => setEventViewShow(false)} data={eventPopupData} />
        <MonthEventViewDialog visible={moreEventViewShow} onHide={() => setMoreEventViewShow(false)} data={moreEventPopupData} path={path} />
        <AddEvent visible={addEvent} onChange={() => setAddEvent(false)} id={null} createDate={createDate} />
        <SlotReserveDialog visible={slotReserveShow} onChange={() => setSlotReserveShow(false)} data={eventPopupData} />
        <ReservationCancelDialog visible={cancelSlotReserveShow} onChange={() => setCancelSlotReserveShow(false)} data={eventPopupData} />
        <PrivateSlotReserveDialog visible={privateSlotReserveShow} onChange={() => setPrivateSlotReserveShow(false)} data={eventPopupData} />
        <SlotListDialog visible={slotListShow} onChange={() => setSlotListShow(false)} data={eventPopupData} />
        {apiEndpoint == "token-statistics" && <TokenListDialog visible={tokenListShow} onChange={() => setTokenListShow(false)} data={eventPopupData} />}
        <WaitListConfirmation
            ref={WaitListConfirmationRef}
            closable={true}
            title=""
            messageTitle={"All courts are booked, we have a waiting list, do you want to join a waitlist?"}
            onConfirm={onConfirmWaitList}
            onCancel={() => WaitListConfirmationRef.current.hidePopUp()}
            onHide={() => WaitListConfirmationRef.current.hidePopUp()} />
        <VivToast ref={toast_Ref} />
        <div className="month-calendar">
            {
                monthDates.map((week, rowInd) => (
                    <div className="grid m-0 calendar-row" key={rowInd}>
                        {
                            week?.week.map((day, ind) => ( apiEndpoint == "token-statistics" ? handleTokenStaticsEventRender(day, rowInd) : handleEventRender(day, rowInd) ))
                        }
                    </div>
                ))
            }
        </div>
    </>);
}

export default MonthCalander;